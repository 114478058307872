const bodyTag   = document.getElementsByTagName('body');
const hdrBar    = document.getElementsByClassName('hdr_bar');

/*
    On Scroll Variables:
    Copied from https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
*/
const revealThreashold = 90;
let last_known_scroll_position = 0;
let ticking = false;
let direction = null;
let last_known_direction = null;
let triggeredOnce = false;

/* ------------------------------------------------------------
Only apply this custom header if we're on the homepage.
------------------------------------------------------------ */

if(bodyTag[0].classList.contains('has-sticky-nav')){
    hdrBar[0].classList.add('is-translucent');

    document.addEventListener('scroll', function(e) {
        if(window.innerWidth > 899){
            last_known_scroll_position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    checkPosition(last_known_scroll_position);
                    ticking = false;
                });

                ticking = true;
            }
        }
    });
}


function checkPosition(scroll_pos){
    if(scroll_pos >= revealThreashold){
            if(!triggeredOnce){

                hdrBar[0].classList.remove('is-translucent');
                hdrBar[0].classList.remove('slide-up');
                hdrBar[0].classList.add('slide-down');

                hdrBar[0].onanimationend = () => {
                    hdrBar[0].classList.remove('slide-down');
                };

                triggeredOnce = true;
            }
    }
    else{
        if(triggeredOnce){
            hdrBar[0].classList.remove('slide-down');
            hdrBar[0].classList.add('slide-up');

            hdrBar[0].onanimationend = () => {
                hdrBar[0].classList.add('is-translucent');
                hdrBar[0].classList.remove('slide-up');
            };

            triggeredOnce = false;
        }
    }
}
